import { HttpRequestMethod } from './http.enum';

export class HttpService {
  get(url: string, headers: Record<string, string>): Promise<any> {
    return this.request(HttpRequestMethod.Get, url, headers);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  post(url: string, headers: Record<string, string>, data: any): Promise<any> {
    return this.request(HttpRequestMethod.Post, url, headers, data);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  request(
    method: HttpRequestMethod,
    url: string,
    headers: Record<string, string>,
    data?: any
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.open(method, url, true);
      this.setHeaders(headers, xhr);
      xhr.send(JSON.stringify(data));
    });
  }

  setHeaders(headers: Record<string, string>, xhr: XMLHttpRequest): void {
    for (let key in headers) {
      xhr.setRequestHeader(key, headers[key]);
    }
  }
}
