export enum EcsEventPrefix {
  Analytics = 'event_',
  Dev = 'dev_',
  Temp = 'temp_',
}

export enum EcsAnalyticsEvent {
  Partner = 'partner'
}

export enum EcsTmsCustomerStatus {
  New = 'new',
  Free = 'cor-fr',
  Paid = 'cor-pd',
  Phx = 'phx-pd'
}

export enum EcsTmsProductType {
  Free = 'fr',
  NoBenefit = 'nb',
  Paid = 'p',
  Regulatory = 'r',
}

export enum EcsTmsUxType {
  DaReg = 'da-reg',
  DaUpsell = 'da-upsell',
  AcrCdi = 'acr-cdi',
  DisputeReg = 'dispute-reg',
  DefaultReg = 'default-reg',
  AcrDisputeReg = 'acr-dispute-reg',
  CmReg = 'cm-reg',
  AcrReg = 'acr-reg',
  AcrFreeReg = 'acr-free-reg',
  SecondaryMbr = 'secondary-mbr',
  AcrUpsell = 'acr-upsell',
  PrefillReg = 'prefill-reg',
  MigratedToken = 'migrated-token',
  MigratedExpiredToken = 'migrated-expired-token',
  MigratedReturn = 'migrated-return',
  RteBoost = 'rte-boost',
  RteTokenValid = 'rte-tkv',
  RteTokenExpired = 'rte-tke',
  RteForgotPassword = 'rte-fpw',
  RTE = 'rte'
}

export enum EcsTmsTrackLinkType {
  Open = 'o',
  External = 'e',
  Download = 'd'
}
