import { ClickStreamSelector } from './click-listener.interface';
import { TmsAttributesType } from './click-listener.enum';
import { StorageService } from '../storage/storage.service';

export class ClickListenerService {
  constructor(private session: StorageService) { }

  addListener(selectors: ClickStreamSelector[] | string = 'a, button', callback: (dataset: DOMStringMap, event: Partial<Event>) => any): void {
    const selectorString = Array.isArray(selectors) ? this.selectorArrayTransformer(selectors) : selectors;
    window.document.addEventListener('click', (event: Event) => {
      const { target } = event;
      if (target) {
        const element = (target as HTMLBaseElement);
        const dataset = element.dataset;

        if (element.querySelectorAll(selectorString)) {
          callback(dataset, event);
        }
      }
    }, true);
  }

  selectorArrayTransformer(selectors: ClickStreamSelector[]): string {
    let selectorString = '';

    selectors.forEach(selector => {
      switch (selector.type) {
        case TmsAttributesType.Attribute:
          selectorString += `[${selector.identifier}], `;
          break;
        case TmsAttributesType.Class:
          selectorString += `.${selector.identifier}, `;
          break;
        case TmsAttributesType.Element:
          selectorString += `${selector.identifier}, `;
          break;
        case TmsAttributesType.Id:
          selectorString += `#${selector.identifier}, `;
          break;
      }
    });

    return selectorString.slice(0, -2);
  }
}

