import { ItemStorage } from './storage.interface';

export class StorageService {
  private storage?: ItemStorage;
  memoryStore: Record<string, string> = {};

  init(useSessionStorage = false): void {
    if (useSessionStorage) {
      this.storage = this.sessionStorage();
    } else {
      this.storage = this.memoryStorage();
    }
  }

  get(name: string): any {
    if (!this.storage) {
      this.storage = this.memoryStorage();
    }
    return this.stringToType(this.storage.getItem(name));
  }

  set(key: string, value: any): void {
    if (!this.storage) {
      this.storage = this.memoryStorage();
    }
    this.remove(key);
    this.storage.setItem(key, this.typeToString(value));
  }

  remove(key: string): void {
    if (!this.storage) {
      this.storage = this.memoryStorage();
    }
   this.storage.removeItem(key);
  }

  private sessionStorage(): ItemStorage {
    let response = this.memoryStorage();

    try {
      const sessionStorage = window.sessionStorage;
      if (sessionStorage) {
        const methods = {
          getItem: (value: string) => sessionStorage.getItem(value),
          setItem: (key: string, value: string) => sessionStorage.setItem(key, value),
          removeItem: (key: string) => sessionStorage.removeItem(key)
        }

        response = methods;
      }
    } catch { }

    return response;
  }

  private memoryStorage(): ItemStorage {
    return {
      getItem: (key: string) => this.memoryStore[key] || null,
      setItem: (key: string, value: string) => this.memoryStore[key] = value,
      removeItem: (key: string) => delete this.memoryStore[key]
    }
  }

  private stringToType(value: any): any {
    let output = null;
    try {
      output = JSON.parse(value);
    } catch (e) {
      output = value;
    }
    return output;
  }

  private typeToString(type: any): string {
    let output = null;
    if (typeof type === 'object') {
      output = JSON.stringify(type);
    } else {
      output = type;
    }
    return output;
  }
}


