import { HttpService } from "../http/http.service";
import { EcsTmsData } from '../analytics/analytics.interface';
import { StorageService } from "../storage/storage.service";
import { envDomainNames } from "./clickstream.constant";
import { Environment } from "./clickstream.interface";

export class ClickstreamService {
  private queueTimer: any;
  private clickstreamUrl: string = '';

  constructor(private http: HttpService, private session: StorageService) { }

  init() {
    this.clickstreamUrl = this.getClickstreamUrl();
  }

  postUiLog(isPublic = false, isLogNow = false): Promise<Response> {
    const headers: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const sessionTms = this.session.get('tmsData') || {};
    const data: Partial<EcsTmsData>[] = [
      ...this.getUiLogs()
    ];
    if (isPublic) {
      headers['x-pr'] = 'uilog';
    } else if (isLogNow) {
      headers['x-pr'] = 'logNow';
    }
    if (sessionTms?.sessionid) {
      headers['x-session-id'] = sessionTms.sessionid;
    }
    if (sessionTms?.synthetictesttag) {
      headers['x-synthetic-test-tag'] = sessionTms.synthetictesttag;
    }

    return this.http.post(this.clickstreamUrl, headers, data);
  }

  pushUiLog(data: Partial<EcsTmsData>): void {
    const queue = this.session.get('clickstreamQueue') || [];
    queue.push(data);
    this.session.set('clickstreamQueue', queue);
  }

  getUiLogs(): Partial<EcsTmsData>[] {
    const queue = this.session.get('clickstreamQueue');
    this.session.set('clickstreamQueue', []);

    return queue;
  }

  startQueue(): void {
    if (!this.queueTimer) {
      this.queueTimer = setInterval(() => {
        const queue = this.session.get('clickstreamQueue');
        if (queue && queue.length > 0) {
          this.postUiLog();
        }
      }, 5000);
    }
  }

  getClickstreamUrl(): string {
    const options = this.session.get('options');

    const env = this.verifyEnvToken(options?.env) ? options?.env : 'stg';
    const domainName = envDomainNames[env as keyof Environment] || envDomainNames['prod'];

    return `https://${ domainName }/api/clickstream/uilog`;
  }

  verifyEnvToken(token: string): boolean {
    const tokens = Object.keys(envDomainNames);
    return tokens.includes(token);
  }
}
