export class DocumentService {
  constructor() { }
  
  onReady(callback: () => any) {
    if (this.getReadyState() === 'complete' || this.getReadyState() === 'interactive') {
      setTimeout(callback, 1);
    } else {
      window.document.addEventListener('DOMContentLoaded', callback, false);
    }
  }

  getReadyState() {
    return window.document.readyState;
  }
}
