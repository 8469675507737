import { HttpService } from './services/http/http.service';
import { ClickListenerService } from './services/click-listener/click-listener.service';
import { ClickstreamService } from './services/clickstream/clickstream.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { DocumentService } from './services/document/document.service';
import { StorageService } from './services/storage/storage.service';
import { EcsTmsData, EcsTmsTrackLink } from './services/analytics/analytics.interface';
import { InitService } from './services/init/init.service';
import { InitOptions } from './services/init/init-options.interface';
import { v4 as uuidv4 } from 'uuid';

export class App {
  private http = new HttpService();
  private session = new StorageService();
  private clickstream = new ClickstreamService(this.http, this.session);
  private clickListener = new ClickListenerService(this.session);
  private document = new DocumentService();
  private analytics = new AnalyticsService(this.clickstream, this.clickListener, this.document, this.session);
  private init = new InitService(this.session, this.analytics);

  getAnalyticsProps(): void {
    window.ECS.analytics = {
      initTmsData: (marketingId: string, partnerClickstreamId: string, sessionId = uuidv4(), useSessionStorage = false, partnerSessionId?: string) => {
        this.analytics.initTmsData(marketingId, partnerClickstreamId, sessionId, useSessionStorage, partnerSessionId)
      },
      fireTrackLink: (tracklink: Partial<EcsTmsTrackLink>) => { this.analytics.fireTrackLink(tracklink) },
      fireAnalyticsEvent: (tmsData: Partial<EcsTmsData>, event?: Event) => { this.analytics.fireAnalyticsEvent(tmsData, event) },
      setTmsData: (tmsData: Partial<EcsTmsData>, overwrite: boolean) => { this.analytics.setTmsData(tmsData, overwrite) },
      removeTmsData: (propertyName: string) => { this.analytics.removeTmsData(propertyName) },
    };
  }

  appInit(): void {
    window.ECS = {
      init: (tmsOption: InitOptions) => { this.init.init(tmsOption); this.getAnalyticsProps(); },
    }
    this.getAnalyticsProps();
  }
}

declare let window: any;
const app = new App();
app.appInit();
