import { ClickstreamService } from '../clickstream/clickstream.service';
import { EcsTmsTrackLinkType } from './analytics.enum';
import {
  EcsTmsDataTemp,
  EcsTmsTrackLink,
  EcsTmsData,
  EcsTmsDataPersistant
} from './analytics.interface';
import { TmsAttributesStringMap, TmsUrlExtractParams } from './analytics.constant';
import { ClickListenerService } from '../click-listener/click-listener.service';
import { ClickStreamSelectors } from '../click-listener/click-listener.constant';
import { DocumentService } from '../document/document.service';
import { StorageService } from '../storage/storage.service';
import { InitOptions } from '../init/init-options.interface';

declare global {
  interface Window { ECS: any }
}
export class AnalyticsService {
  private tmsData = {} as Partial<EcsTmsData>;

  constructor(
    private clickstream: ClickstreamService,
    private clickListener: ClickListenerService,
    private document: DocumentService,
    private storage: StorageService
  ) { }

  init(initialData?: Partial<EcsTmsDataPersistant>, isSpa = false, useQueryParams = false): void {
    const marketingId = initialData?.member?.marketingid || '';
    const sessionId = initialData?.sessionid || '';
    const partnerSessionId = initialData?.partnersessionid || '';
    const partnerClickstreamId = initialData?.partnerclickstreamid || 'missing-id';
    this.clickstream.init();
    this.initTmsData(marketingId, partnerClickstreamId, sessionId, useQueryParams, partnerSessionId);
    this.getQueryParams();
    this.clickstream.startQueue();

    if (!isSpa) {
      this.document.onReady(() => {
        this.remapOnclick();
        this.clickListener.addListener(ClickStreamSelectors, (dataset, event) => {
          if (dataset.clicked !== 'true') {
            if (event.preventDefault) {
              event.preventDefault();
            }
            this.fireTrackLink(this.generateTracklinkData(dataset), event);
          }
        });
      });
    }
  }

  initTmsData(marketingid: string, partnerclickstreamid: string, sessionid: string, useQueryParams = false, partnersessionid?: string): void {
    const sessionTms = this.storage.get('tmsData');
    this.tmsData = sessionTms || this.tmsData;
    window.ECS = {};
    if (useQueryParams) { this.processTmsQueryParams(); }
    if (!this.tmsData.app) {
      this.tmsData = {
        app: {
          name: 'partner-sdk',
          width: window.outerWidth,
          height: window.outerHeight,
          version: '1.0.0'
        },
        member: {
          marketingid
        },
        path: location.pathname,
        sessionid,
        partnerclickstreamid
      };
      if (partnersessionid) {
        this.tmsData.partnersessionid = partnersessionid;
      }

      this.storage.set('tmsData', this.tmsData);
    }
  }

  fireTrackLink(trackLink: Partial<EcsTmsTrackLink>, event?: Partial<Event>): void  {
    const tms = {
      ...this.tmsData,
      evt: TmsAttributesStringMap.tmsTrackLink.eventName,
      trackLink
    };
    const options = this.storage.get('options') || {};
    this.clickstream.pushUiLog(tms);

    this.postUiLog(options, event);
  }

  fireAnalyticsEvent(tempTmsData: Partial<EcsTmsDataTemp> = {}, event?: Event): void {
    const location = window.location;
    const domain = `${location.protocol}//${location.hostname}`;
    const options = this.storage.get('options') || {};

    tempTmsData = {
      url: location.href,
      domain,
      ...tempTmsData,
      ...this.tmsData
    };
    this.clickstream.pushUiLog(tempTmsData);

    this.postUiLog(options, event);
  }

  setTmsData(newTmsData: Partial<EcsTmsDataPersistant>, overwriteChildren = false): void {
    const tmsData = this.tmsData;
    newTmsData = newTmsData || {};
    Object.keys(newTmsData).forEach((key) => {
      const tmsDataValue = newTmsData[key as keyof EcsTmsDataPersistant];
      if (tmsDataValue && tmsDataValue instanceof Object && !overwriteChildren) {
        tmsData[key] = {
          ...(tmsData[key as keyof EcsTmsData] || {}),
          ...tmsDataValue
        };
      } else {
        tmsData[key] = newTmsData[key as keyof EcsTmsDataPersistant];
      }
    });
    this.storage.set('tmsData', tmsData);
    this.tmsData = tmsData;
  }

  removeTmsData(propertyName: string): void {
    const tmsData = this.tmsData;
    delete tmsData[propertyName];
    this.storage.set('tmsData', tmsData);
    this.tmsData = tmsData;
  }

  remapOnclick(): void {
    const tmsSelectors = this.getTmsSelectors();
    const elements: NodeListOf<HTMLBaseElement> = document.querySelectorAll(tmsSelectors);

    elements.forEach(element => {
      const onclickAttr = element.getAttribute('onclick') || '';
      if (onclickAttr.length > 0) {
        element.setAttribute('data-onclick', onclickAttr);
        element.removeAttribute('onclick');
      }
    });
  }

  getTmsType(type?: string): EcsTmsTrackLinkType {
    type = type?.toLowerCase();
    switch (type) {
      case 'o':
      case 'open':
        return EcsTmsTrackLinkType.Open;
      case 'd':
      case 'download':
        return EcsTmsTrackLinkType.Download;
      default:
        return EcsTmsTrackLinkType.External;
    }
  }

  generateTracklinkData(dataset: DOMStringMap): Partial<EcsTmsTrackLink> {
    const tracklink: Partial<EcsTmsTrackLink> = {};

    if (dataset.tmsTrackLink) {
      tracklink.identifier = dataset.tmsTrackLink;
    }
    if (dataset.tmsPlacement) {
      tracklink.placement = dataset.tmsPlacement;
    }
    if (dataset.tmsType) {
      tracklink.type = this.getTmsType(dataset.tmsType);
    }

    return tracklink;
  }

  getTmsSelectors(): string {
    const tmsKeys = Object.keys(TmsAttributesStringMap);
    return tmsKeys.map(key => {
      return `[${TmsAttributesStringMap[key].dataId}]`;
    }).join(', ');
  }

  getQueryParams(): Record<string, string> {
    const paramString = window.location.search.slice(1);
    const queryParams: Record<string, any> = {};
    const params: string[] = paramString.split('&');

    params.forEach(param => {
      const paramArr = param.split('=');
      queryParams[paramArr[0]] = paramArr[1];
    });

    return queryParams;
  }

  processTmsQueryParams(): void {
    const params = this.getQueryParams();
    const tmsData: Partial<EcsTmsDataPersistant> = {};

    Object.keys(params).forEach(param => {
      if (TmsUrlExtractParams.includes(param)) {
        const key: keyof Partial<EcsTmsDataPersistant> = <keyof Partial<EcsTmsDataPersistant>>param;
        if (param === 'cc') {
          tmsData.cc = this.queryCcFilter(params.cc);
        } else if (param === 'sessionId') {
          tmsData.sessionid = this.queryGeneralFilter(params.sessionId);
        } else if (param === 'syntheticTestTag') {
          tmsData.synthetictesttag = this.queryGeneralFilter(params.syntheticTestTag);
        } else if (param === 'partnerClickstreamId') {
          tmsData.synthetictesttag = this.queryGeneralFilter(params.syntheticTestTag);
        } else if (param === 'marketingId') {
          tmsData.member = tmsData.member || {};
          tmsData.member.marketingid = this.queryGeneralFilter(params.marketingId);
        } else {
          tmsData[key] = this.queryGeneralFilter(params[key]);
        }
      }
    });

    this.setTmsData(tmsData);
  }

  queryCcFilter(value: string): string {
    return value.substring(0, 1).replace(/[^a-zA-Z0-9 \-_]/g, '') +
      value.substring(1).replace(/[^a-zA-Z0-9 \-_%]/g, '');
  }

  queryGeneralFilter(value: string): string {
    return value.replace(/[^a-zA-Z0-9 \-_]/g, '');
  }

  resumeDefault(event: Partial<Event>): void {
    const { target } = event;

    if (target) {
      const element = target as HTMLBaseElement;
      this.handleOnclick(element);
      switch (element.nodeName) {
        case 'A':
          this.handleAnchorRedirect(element);
          break;
        case 'BUTTON':
          this.handleButtonAction(element);
          break;
      }
    }
  }

  handleAnchorRedirect(element: HTMLBaseElement): void {
    const url = element.href;
    const targetAttrib = element.target || '';

    if (url.startsWith('http')) {
      switch (targetAttrib.toLowerCase()) {
        case '_blank':
          window.open(url, targetAttrib);
          break;
        case '_parent':
          parent.location.href = url;
          break;
        case '_top':
          window.top?.open(url);
          break;
        default:
          window.location.href = url;
      }
    }
  }

  handleButtonAction(element: HTMLBaseElement): void {
    const form = element.closest('form');
    form?.submit();
  }

  handleOnclick(element: HTMLBaseElement): void {
    const onclickAttr = element.dataset.onclick || '';

    if (onclickAttr.length > 0) {
      element.setAttribute('onclick', onclickAttr);
      element.setAttribute('data-clicked', 'true');

      element.click();

      element.removeAttribute('onclick');
      element.removeAttribute('data-clicked');
    }
  }

  postUiLog(options: InitOptions, event?: Partial<Event>): void {
    if (event) {
      this.clickstream.postUiLog(false, true).then(() => this.resumeDefault(event), () => this.resumeDefault(event));
    } else if (!options?.usePolling) {
      this.clickstream.postUiLog(false, true).then(() => {}, () => {});
    }
  }
}
