import { TmsAttributesType } from './click-listener.enum';
import { ClickStreamSelector } from './click-listener.interface';

export const TmsAttributesStringMap: Record<string, string> = {
  class: 'tmsClass',
  placement: 'tmsPlacement',
  tracklink: 'tmsTrackLink',
  type: 'tmsType'
}

export const ClickStreamSelectors: ClickStreamSelector[] = [
  {
    type: TmsAttributesType.Attribute,
    identifier: 'data-tms-class',
    stringMap: TmsAttributesStringMap.class
  },
  {
    type: TmsAttributesType.Attribute,
    identifier: 'data-tms-placement',
    stringMap: TmsAttributesStringMap.placement
  },
  {
    type: TmsAttributesType.Attribute,
    identifier: 'data-tms-track-link',
    stringMap: TmsAttributesStringMap.tracklink
  },
  {
    type: TmsAttributesType.Attribute,
    identifier: 'data-tms-type',
    stringMap: TmsAttributesStringMap.type
  }
];

export const envDomainNames: Record<string, string> = {
  dev: 'develop.dev.experiancs.com/',
  int: 'integration.projectcorvette.us/',
  stg: 'stage.experiancs.com/',
  prod: 'usa.experian.com'
};
