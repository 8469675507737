import { EcsTmsAttributesMap } from './analytics.interface';

export const TmsAttributesStringMap: EcsTmsAttributesMap = {
  tmsClass: {
    dataId: 'data-tms-class',
    eventName: 'event_class'
  },
  tmsPlacement: {
    dataId: 'data-tms-placement',
    eventName: 'event_placement'
  },
  tmsTrackLink: {
    dataId: 'data-tms-track-link',
    eventName: 'event_trackLink'
  },
  tmsType: {
    dataId: 'data-tms-type',
    eventName: 'event_type'
  }
}

export const TmsUrlExtractParams = [
  'cc',
  'cid',
  'partnerapplicationid',
  'ref',
  'sessionId',
  'marketingId',
  'syntheticTestTag'
];

