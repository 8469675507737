import { AnalyticsService } from '../analytics/analytics.service';
import { StorageService } from '../storage/storage.service';
import { InitOptions } from './init-options.interface';
import { v4 as uuidv4 } from 'uuid';

export class InitService {
  constructor(private storage: StorageService, private analytics: AnalyticsService) {}

  init(tmsOption: InitOptions): void {
    this.storage.init(!!tmsOption?.useSessionStorage);
    if (tmsOption.env) {
      let options = this.storage.get('options') || {};
      options.env = options.env || tmsOption.env;
      this.storage.set('options', options);
    }
    tmsOption.analytics = tmsOption.analytics || {};
    tmsOption.analytics.sessionid = tmsOption.sessionId || uuidv4();
    tmsOption.analytics.partnerclickstreamid = tmsOption.partnerClickstreamId || 'missing-id';
    tmsOption.analytics.partnersessionid = tmsOption.partnerSessionId;
    tmsOption.analytics.member = {
      marketingid: tmsOption.marketingId || '00000000-0000-0000-0000-000000000000'
    }

    this.analytics.init(tmsOption.analytics, !!tmsOption.isSpa, !!tmsOption.useQueryParams);
  }
}
